import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

// styles
const pageStyles = {
  marginTop: 100,
  color: "var(--primary-color)",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 24,
  maxWidth: 320,
  fontSize: "1.5em",
  fontWeight: 500,
}

const paragraphStyles = {
  marginBottom: 48,
  lineHeight: 1.5,
  color: "var(--secondary-color",
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

const NotFoundPage = () => {
  return (
    <Layout>
      <main style={pageStyles}>
        <title>Not found</title>
        <h1 style={headingStyles}>Page not found</h1>
        <p style={paragraphStyles}>
          The page you are looking for has moved or doesn't exist. Try finding
          it through the homepage?
          <br />
        </p>
      </main>
    </Layout>
  )
}

export default NotFoundPage
